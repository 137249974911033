
.delete-window {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:  90vw;
  height:180px;
  background-color: #ffffff;
  z-index: 11;
  padding: 20px;
  border: 3px solid var(--color_main);
}
.delete-window div.message {
  font-size: .9em;
  margin-bottom: 30px;
  margin-top: 30px;
}
.delete-window div.button_wrap{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.delete-window div.button_wrap div.button
{
  margin: 0 5px;
  width: 130px;
  padding: 5px;
  color: var(--color_main);
  background-color:#fff;
  border: 1px solid var(--color_main);
  text-align: center;
}

